import { useState } from "react";
import { Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import { getNotifications } from "../util/api";

function Header() {
  const notifications = getNotifications();
  const [notificationsIsOpen, setNotificationsIsOpen] = useState(false);
  const [notificationsUnread, setNotificationsUnread] = useState(true);

  const [dateRange, setDateRange] = useState([
    new Date("2022-02-19 22:13:00"),
    new Date("2022-02-19 22:16:30"),
  ]);

  return (
    <header className="flex justify-between items-center py-4 px-6 bg-white border-b-2 border-blue-600">
      <div className="flex items-center flex-grow">
        <div className="relative flex flex-grow mx-4 lg:mx-0 items-center justify-center">
          <div className="border-2 rounded-md">
            <i className="bi bi-search text-gray-500 px-4 form-input border-r"></i>
            <input
              className="form-input w-32 md:w-64 lg:w-[32rem] rounded-md px-4 border-0 focus:border-indigo-600"
              type="text"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center flex-grow">
        <DateRangePicker
          format="yyyy-MM-dd HH:mm:ss"
          value={dateRange}
          onChange={setDateRange}
        />
      </div>
      <div className="flex items-center">
        <div className="relative">
          <button
            className="text-lg flex mx-4 text-gray-600 focus:outline-none rounded-full  h-8 w-8 hover:bg-gray-200 items-center justify-center relative"
            onClick={() => {
              setNotificationsIsOpen(!notificationsIsOpen);
              setNotificationsUnread(false);
            }}
          >
            <i className="bi bi-bell"></i>
            {notificationsUnread && (
              <div className="bg-red-500 rounded-full w-2 h-2 absolute top-0 right-1" />
            )}
          </button>
          <div
            className={`absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-xl overflow-hidden z-10 ${
              notificationsIsOpen || "hidden"
            }`}
          >
            {notifications.map((notification) => (
              <Link
                to={`/dashboard/customers/${notification.customer_id}`}
                className="flex items-center px-4 py-3 text-gray-600 hover:bg-gray-100 -mx-2 hover:no-underline active:no-underline focus:no-underline"
              >
                <i
                  className={`h-8 w-8 text-lg bi ${
                    {
                      yellow: "bi-exclamation-circle-fill text-yellow-500",
                      green: "bi-check-circle-fill text-green-500",
                      red: "bi-exclamation-circle-fill text-red-500",
                    }[notification.code]
                  } rounded-full mx-2 items-center flex-shrink`}
                />
                <p className="text-sm flex-1">
                  <span className="font-bold" href="#">
                    {notification.customer_name}
                  </span>{" "}
                  {notification.message}
                  <div className="text-gray-400">{notification.timestamp}</div>
                </p>
              </Link>
            ))}
          </div>
        </div>

        <div className="relative">
          <button className="relative block h-8 w-8 rounded-full overflow-hidden shadow focus:outline-none">
            <img
              className="h-full w-full object-cover"
              src="https://avatars.dicebear.com/api/identicon/2131.svg"
              alt="Your avatar"
            />
          </button>

          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10 hidden">
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white"
            >
              Profile
            </a>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white"
            >
              Products
            </a>
            <a
              href="/login"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white"
            >
              Logout
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
