import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Modal } from "rsuite";
import CreatableSelect from "react-select/creatable";
import { getCustomers } from "../util/api";

const endpointOptions = [
  { value: "/api/dogs", label: "/api/dogs" },
  { value: "/api/dogs/:dogId", label: "/api/dogs/:dogId" },
  { value: "/api/dogs/foster", label: "/api/dogs/foster" },
  { value: "/api/dogs/purchase", label: "/api/dogs/purchase" },
  { value: "/api/cats", label: "/api/cats" },
  { value: "/api/cats/:catId", label: "/api/cats/:catId" },
];

const responseStatusOptions = [
  { value: "200", label: "200 (OK)" },
  { value: "400", label: "400 (Bad Request)" },
  { value: "401", label: "401 (Unauthorized)" },
  { value: "403", label: "403 (Forbidden)" },
  { value: "404", label: "404 (Not Found)" },
  { value: "500", label: "500 (Internal Server Error)" },
];

const routePrefixOptions = [
  { value: "/api/", label: "/api/" },
  { value: "/api/dogs/", label: "/api/dogs/" },
  { value: "/api/cats/", label: "/api/cats/" },
  { value: "/api/v2/", label: "/api/v2/" },
];

function GraphCreationModal() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const customerQuery = useQuery("all-customer", getCustomers());
  const [billingPlanOptions, setBillingPlanOptions] = useState([]);
  const [dataset, setDataset] = useState("billing_plan");
  const [customerOptions, setCustomerOptions] = useState([]);

  // When customerQuery is done loading, set the customerOptions
  useEffect(() => {
    if (customerQuery.status === "success") {
      const newCustomerOptions = customerQuery.data.data.map((customer) => ({
        value: customer.foreign_id,
        label: customer.name,
      }));
      const newBillingPlanOptions = [
        ...new Set(
          customerQuery.data.data.map((customer) => customer.billing_plan_name)
        ),
      ]
        .filter((bp) => bp)
        .map((bp) => ({ value: bp, label: bp }));
      setCustomerOptions(newCustomerOptions);
      setBillingPlanOptions(newBillingPlanOptions);
    }
  }, [customerQuery.status]);

  function getOptionsForValues() {
    switch (dataset) {
      case "billing_plan":
        return billingPlanOptions;
      case "customer":
        return customerOptions;
      case "endpoint":
        return endpointOptions;
      case "response_status":
        return responseStatusOptions;
      case "route_prefix":
        return routePrefixOptions;
      default:
        return [];
    }
  }

  return (
    <>
      <button
        onClick={() => setModalIsOpen(true)}
        className="min-w-max transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white rounded-lg text-md shadow-sm hover:shadow-md font-semibold text-center flex-shrink p-2 m-2 mt-5 disabled:bg-gray-300"
      >
        <span className="bi bi-plus-lg mr-2"></span>
        Add Chart
      </button>
      <Modal
        open={modalIsOpen}
        overflow={false}
        onClose={() => setModalIsOpen(false)}
      >
        <Modal.Header>
          <Modal.Title className="mb-4 flex flex-row items-center">
            <div className="font-semibold">Add New Chart to: </div>
            <div className="ml-1">
              <select
                className="form-input border-gray-300 rounded-md w-full"
                value={dataset}
                onChange={(e) => setDataset(e.target.value)}
              >
                <option value="custom">Custom Charts</option>
                <option value="revenue">Revenue</option>
                <option value="requests">Requests</option>
                <option value="responses">Responses</option>
                <option value="errors">Errors</option>
                <option value="performance">Performance</option>
              </select>
            </div>
          </Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body className="grid grid-cols-2 gap-3 p-[1px]">
          <div className="">
            <label className="font-semibold  text-gray-600 block">Title</label>
            <input
              type="text"
              className="form-input border-gray-300 rounded-md w-full"
            />
          </div>

          <div className="">
            <label className="font-semibold  text-gray-600 block w-full">
              Subtitle
            </label>
            <input
              type="text"
              className="form-input border-gray-300 rounded-md"
            />
          </div>
          <div className="">
            <label className="font-semibold  text-gray-600 block">
              Purpose
            </label>
            <select className="form-input border-gray-300 rounded-md w-full">
              <option value="line">See Change Over Time (Line)</option>
              <option value="bar">Compare Usage (Bar)</option>
              <option value="bar">Compare Ranges of Values (Histogram)</option>
              <option value="pie">See a Breakdown (Pie)</option>
              <option value="donut">See a Breakdown (Donut)</option>
            </select>
          </div>
          <div className="">
            <label className="font-semibold  text-gray-600 block">Data</label>
            <select
              className="form-input border-gray-300 rounded-md w-full"
              value={dataset}
              onChange={(e) => setDataset(e.target.value)}
            >
              <option value="billing_plan">Billing Plan</option>
              <option value="revenue_cumulative">Revenue (Cumulative)</option>
              <option value="revenue_cumulative">Revenue (Pre Request)</option>
              <option value="customer">Customer</option>
              <option value="endpoint">Endpoint</option>
              <option value="latency">Latency</option>
              <option value="response_status">Response Status</option>
              <option value="route_prefix">Route Prefix</option>
              <option value="unit">Unit</option>
              <option value="credits_remaining">Credits Remaining</option>
              <option value="credits_used">Credits Used</option>
              <option value="time_bucket">Request Time Bucket</option>
            </select>
          </div>
          <div className="">
            <label className="font-semibold  text-gray-600 block">
              Displayed Values
            </label>
            <CreatableSelect
              isMulti
              classNamePrefix="react-select"
              formatCreateLabel={(inputValue) => `${inputValue}`}
              createOptionPosition="first"
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              options={getOptionsForValues()}
              placeholder="All Values"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setModalIsOpen(false)}
            className="transition duration-200 bg-gray-300 hover:bg-gray-400 focus:bg-gray-400 focus:shadow-sm focus:ring-4 focus:ring-gray-400 focus:ring-opacity-50 text-black rounded-lg text-md shadow-sm hover:shadow-md font-semibold text-center flex-shrink p-3 m-2 mt-6 disabled:bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={() => setModalIsOpen(false)}
            className="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white rounded-lg text-md shadow-sm hover:shadow-md font-semibold text-center flex-shrink p-3 m-2 mt-6 disabled:bg-gray-300"
          >
            Create
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GraphCreationModal;
