import { useEffect, useState } from "react";
import { Modal } from "rsuite";
import md5 from "crypto-js/md5";

function DashboardWidget({ children, className }) {
  const [embedModalIsOpen, setEmbedModalIsOpen] = useState(false);
  const [embedCode, setEmbedCode] = useState(md5("1"));

  useEffect(() => {
    if (embedModalIsOpen) {
      const seed = Date.now().toLocaleString() + embedModalIsOpen;
      const newCode = md5(seed);
      setEmbedCode(newCode);
    }
  }, [embedModalIsOpen]);

  return (
    <div
      className={
        "bg-white group relative shadow rounded-lg p-4 sm:p-6 xl:p-8 " +
        className
      }
    >
      <div
        onClick={() => setEmbedModalIsOpen(true)}
        className="w-10 h-10 bg-white transition duration-300 hover:bg-blue-700 hover:text-white hover:shadow-2xl active:shadow-none rounded-full flex justify-center items-center absolute top-[-1.25rem] opacity-0 group-hover:opacity-100 cursor-pointer shadow"
      >
        <span className="bi bi-code-slash" />
      </div>
      {children}
      <Modal open={embedModalIsOpen} onClose={() => setEmbedModalIsOpen(false)}>
        <Modal.Header>
          <Modal.Title>Embed Dashboard Widget</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          To embed this widget in a different page, copy and paste the following
          code into your page's markup at the location you'd like the widget to
          appear:
          <div className="mt-4 break-all font-mono p-4 bg-gray-100 rounded">
            {`<div style="overflow: auto; -webkit-overflow-scrolling: touch;"><iframe src="https://admin.getraio.com/embed/${embedCode}" width="800px" height="550px" frameborder="0" scrolling="yes"></iframe></div>`}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setEmbedModalIsOpen(false)}
            className="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white rounded-lg text-md shadow-sm hover:shadow-md font-semibold text-center flex-shrink p-3 m-2 mt-6 disabled:bg-gray-300"
          >
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DashboardWidget;
