import { useState } from "react";
import CreatableSelect from "react-select/creatable";
import ReactPrismEditor from "react-prism-editor";
import { useQueryClient, useMutation } from "react-query";
import { updateBillingPlan } from "../util/api";

import BillingPlanEditor from "./BillingPlanEditor";

const endpointOptions = [
  { value: "/api/dogs", label: "/api/dogs" },
  { value: "/api/dogs/:dogId", label: "/api/dogs/:dogId" },
  { value: "/api/dogs/foster", label: "/api/dogs/foster" },
  { value: "/api/dogs/purchase", label: "/api/dogs/purchase" },
  { value: "/api/cats", label: "/api/cats" },
  { value: "/api/cats/:catId", label: "/api/cats/:catId" },
];

const methodOptions = [
  { value: "GET", label: "GET" },
  { value: "POST", label: "POST" },
  { value: "PUT", label: "PUT" },
  { value: "DELETE", label: "DELETE" },
  { value: "PATCH", label: "PATCH" },
];

function BillingPlanEntry({ planData }) {
  const queryClient = useQueryClient();

  const [name, setName] = useState(planData.name);
  const [planDetails, setPlanDetails] = useState(planData.plan.details || {});
  const [isEditing, setIsEditing] = useState(false);

  const updatePlanMutation = useMutation(updateBillingPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries("billing-plans-all");
      setIsEditing(false);
    },
    onError: (err) => console.log(err),
  });

  function renderActionButtons() {
    return (
      <div className="flex flex-row justify-end">
        <button
          className="transition duration-200 bg-red-500 hover:bg-red-600 focus:bg-red-700 focus:shadow-sm focus:ring-4 focus:ring-red-500 focus:ring-opacity-50 text-white rounded-lg text-md shadow-sm hover:shadow-md font-semibold text-center flex-shrink p-3 m-2 mt-6 disabled:bg-gray-300"
          onClick={() => setIsEditing(false)}
          disabled={updatePlanMutation.isLoading}
        >
          <span className="bi bi-x-lg mr-2"></span>
          Cancel
        </button>
        <button
          className="transition duration-200 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 text-white rounded-lg text-md shadow-sm hover:shadow-md font-semibold text-center flex-shrink p-3 m-2 mt-6 disabled:bg-gray-300"
          onClick={() => {
            updatePlanMutation.mutate({
              id: planData.id,
              data: {
                name: name,
                plan: { details: planDetails },
              },
            });
          }}
          disabled={updatePlanMutation.isLoading}
        >
          <span className="bi bi-check-lg mr-2"></span>
          Save
        </button>
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 m-4">
      <div className="flex items-center flex-row">
        {isEditing ? (
          <input
            className="form-input rounded-md px-4 border-2 border-gray-200 focus:border-blue-700 focus:border-[1px]"
            type="text"
            placeholder="Plan Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        ) : (
          <h3 className="text-xl font-bold text-gray-900">{name}</h3>
        )}
        <button
          className="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center flex-shrink p-2 m-2 ml-4 disabled:bg-gray-300"
          onClick={() => setIsEditing(true)}
          disabled={isEditing}
        >
          <span className="bi bi-pencil mr-2"></span>
          Edit
        </button>
        <div className="flex-grow"></div>
        {!isEditing && (
          <div className="text-gray-500 italic">
            Applied to {planData.customer_count} customers
          </div>
        )}
      </div>
      {isEditing && (
        <BillingPlanEditor
          planDetails={planDetails}
          onPlanDetailsChange={setPlanDetails}
        />
      )}
      {isEditing && renderActionButtons()}
    </div>
  );
}

export default BillingPlanEntry;
