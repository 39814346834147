import DashboardWidget from "./DashboardWidget";
import { useQuery } from "react-query";
import { getResponseCodes } from "../../util/api";
import { Loader } from "rsuite";
import { TODO_FAKE_REVENUE_PER_CALL } from "../../util/constants";

function RevenueTotals({ className, customerId }) {
  const { isLoading, error, data } = useQuery(
    ["response-codes-all", customerId],
    getResponseCodes(customerId)
  );

  if (isLoading) {
    return (
      <DashboardWidget className={className}>
        <Loader size="md" center />
      </DashboardWidget>
    );
  }

  if (error) {
    console.log(error);
  }

  const usage = data.data;
  const requests = usage
    .map((d) => parseInt(d.count))
    .reduce((a, b) => a + b, 0);

  // TODO: Make these values all real
  const revenueMTD = requests * TODO_FAKE_REVENUE_PER_CALL;
  const revenueYTD = revenueMTD * 3.2;
  const revenueAllTime = revenueYTD * 5.1;

  return (
    <DashboardWidget className={className}>
      <div className="flex h-full flex-col items-center justify-around py-5">
        <div className="text-center">
          <span className="text-2xl sm:text-5xl leading-none font-bold text-gray-900">
            ${revenueMTD.toFixed(2)}
          </span>
          <h3 className="text-base font-normal text-gray-500">Revenue (MTD)</h3>
        </div>
        <div className="text-center">
          <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-500">
            ${revenueYTD.toFixed(2)}
          </span>
          <h3 className="text-base font-normal text-gray-500">Revenue (YTD)</h3>
        </div>
        <div className="text-center">
          <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-500">
            ${revenueAllTime.toFixed(2)}
          </span>
          <h3 className="text-base font-normal text-gray-500">
            Revenue (All Time)
          </h3>
        </div>
      </div>
    </DashboardWidget>
  );
}

export default RevenueTotals;
