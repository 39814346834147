import DashboardWidget from "./DashboardWidget";
import Chart from "react-apexcharts";

function CustomChart({ title, subtitle, type, x, ys, className }) {
  let options, series;

  if (type === "bar") {
    options = {
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: x,
      },
    };
    series = ys.map((y) => ({ data: y, name: "Requests" }));
  } else if (type === "pie") {
    options = {
      dataLabels: {
        enabled: false,
      },
      labels: x,
    };
    series = ys ? ys[0] : [];
  }

  return (
    <DashboardWidget className={`${className} flex flex-col`}>
      <div className="flex items-center justify-between mb-4">
        <div className="flex-shrink-0">
          <h3 className="text-xl font-bold text-gray-900 mb-2">{title}</h3>
          {subtitle && (
            <span className="text-base font-normal text-gray-500">
              {subtitle}
            </span>
          )}
        </div>
      </div>
      <div id="main-chart" className="flex-grow flex">
        <Chart
          series={series}
          options={options}
          type={type}
          width="100%"
          height="200"
          className="flex-grow flex justify-center flex-col"
        />
      </div>
    </DashboardWidget>
  );
}

export default CustomChart;
