import { Routes, Route } from "react-router-dom";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import Root from "./layouts/Root";
import Overview from "./pages/AdminDashboard/Overview";
import Login from "./pages/Login";
import BillingPlans from "./pages/AdminDashboard/BillingPlans";
import Customers from "./pages/AdminDashboard/Customers";
import CustomerOverview from "./pages/AdminDashboard/CustomerOverview";
import Integrations from "./pages/AdminDashboard/Integrations";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Root />}>
        <Route path="dashboard" element={<AdminDashboard />}>
          <Route index element={<Overview />} />
          <Route path="customers">
            <Route index element={<Customers />} />
            <Route path=":customerId" element={<CustomerOverview />} />
          </Route>
          <Route path="billing-plans" element={<BillingPlans />} />
          <Route
            path="integrations"
            element={
              <div>
                <Integrations />
              </div>
            }
          />
          <Route path="settings" element={<div>Settings</div>} />
        </Route>
        <Route index element={<Login />} />
      </Route>
    </Routes>
  );
}

export default App;
