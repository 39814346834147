import IntegrationEntry from "../../components/IntegrationEntry";
import stripe from "../../assets/images/integrations/stripe.png";
import salesforce from "../../assets/images/integrations/salesforce.png";
import tableau from "../../assets/images/integrations/tableau.png";
import productboard from "../../assets/images/integrations/productboard.png";
import quickbooks from "../../assets/images/integrations/quickbooks.png";
import zapier from "../../assets/images/integrations/zapier.png";
import bill from "../../assets/images/integrations/bill.png";
import hubspot from "../../assets/images/integrations/hubspot.png";
import pilot from "../../assets/images/integrations/pilot.png";

function Integrations() {
  return (
    <div className="grid grid-cols-5 gap-x-4 gap-y-8 m-4 my-8 auto-rows-fr">
      <IntegrationEntry
        name="Stripe"
        logo={stripe}
        summary="Handle invoice payments through Stripe"
        enabled
      />
      <IntegrationEntry
        name="Bill.com"
        summary="Handle invoice payments through Bill.com"
        logo={bill}
      />
      <IntegrationEntry
        name="Salesforce"
        logo={salesforce}
        summary="Import new customers automatically from Salesforce"
        enabled
      />
      <IntegrationEntry
        name="Hubspot"
        logo={hubspot}
        summary="Import new customers automatically from Hubspot"
      />
      <IntegrationEntry
        name="Tableau"
        summary="Give Tableau access to your API usage and billing analytics"
        logo={tableau}
      />
      <IntegrationEntry
        name="ProductBoard"
        logo={productboard}
        summary="Show relevant ProductBoard features Raio's customer overviews"
        comingsoon
      />
      <IntegrationEntry
        name="QuickBooks"
        summary="Share data with QuickBooks accounting or invoicing"
        logo={quickbooks}
      />
      <IntegrationEntry
        name="Pilot"
        logo={pilot}
        summary="Sync financial data with Pilot bookkeeping"
        comingsoon
      />
      <IntegrationEntry
        name="Zapier"
        summary="Send events to Zapier for use in other integrations"
        logo={zapier}
        comingsoon
      />
    </div>
  );
}

export default Integrations;
