function IntegrationEntry({ name, summary, logo, comingsoon, enabled }) {
  return (
    <div
      className={`bg-white group relative shadow rounded-lg p-4 sm:p-6 xl:p-8 flex flex-col items-center`}
    >
      <img className="h-24  m-2" src={logo} />
      <div className={"flex-grow italic text-gray-500 py-5"}>{summary}</div>
      {enabled ? (
        <div className="text-green-500 p-3 text-xl">
          <span className="bi bi-check-lg mr-1"></span>
          Enabled
        </div>
      ) : (
        <button
          className="transition duration-200 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 text-white rounded-lg text-md shadow-sm hover:shadow-md font-semibold text-center flex-shrink p-3 m-2 mt-6 disabled:bg-gray-300"
          onClick={() => {}}
          disabled={comingsoon}
        >
          {comingsoon ? (
            "Coming Soon"
          ) : (
            <>
              <span className="bi bi-check-lg mr-1"></span>
              Enable
            </>
          )}
        </button>
      )}
    </div>
  );
}

export default IntegrationEntry;
