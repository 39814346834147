import DashboardWidget from "./DashboardWidget";
import { useQuery } from "react-query";
import { getRequestsByPath, TODO_FIX_PATH_NAME } from "../../util/api";
import Chart from "react-apexcharts";
import { Loader } from "rsuite";
import { TODO_FAKE_REVENUE_PER_CALL } from "../../util/constants";

function RevenueByEndpoint({ className, customerId }) {
  const { isLoading, error, data } = useQuery(
    ["requests-all-by-path", customerId],
    getRequestsByPath(customerId)
  );

  if (isLoading) {
    return (
      <DashboardWidget className={className}>
        <Loader size="md" center />
      </DashboardWidget>
    );
  }

  if (error) {
    console.log(error);
  }

  let graphData = {};
  data.data.forEach((item) => {
    if (graphData[item.path] === undefined) {
      graphData[item.path] = 0;
    }
    graphData[item.path] += parseInt(item.count);
  });

  let series = [
    {
      name: "Revenue (MTD)",
      data: [],
    },
  ];
  let paths = [];

  Object.entries(graphData).forEach(([path, count]) => {
    if (count <= 1) return;
    paths.push(TODO_FIX_PATH_NAME(path));
    series[0].data.push(count);
  });

  const options = {
    xaxis: {
      categories: paths,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      formatter: function (val, opt) {
        return "$" + val;
      },
    },
    tooltip: {
      y: {
        formatter: function (val, opt) {
          return "$" + val;
        },
      },
    },
  };

  return (
    <DashboardWidget className={className}>
      <div className="flex items-center justify-between mb-4">
        <div className="flex-shrink-0">
          <h3 className="text-xl font-bold text-gray-900 mb-2">
            Revenue by Endpoint
          </h3>
          <span className="text-base font-normal text-gray-500">
            Month-to-Date
          </span>
        </div>
      </div>
      <div id="main-chart">
        <Chart
          options={options}
          series={series}
          type="bar"
          width="100%"
          height="300"
        />
      </div>
    </DashboardWidget>
  );
}

export default RevenueByEndpoint;
