import DashboardWidget from "./DashboardWidget";
import { useQuery } from "react-query";
import { getUsage } from "../../util/api";
import Chart from "react-apexcharts";
import { Loader } from "rsuite";

function RequestsGraph({ className, customerId }) {
  const { isLoading, error, data } = useQuery(
    ["usage-all", customerId],
    getUsage(customerId)
  );

  if (isLoading) {
    return (
      <DashboardWidget className={className}>
        <Loader size="md" center />
      </DashboardWidget>
    );
  }

  if (error) {
    console.log(error);
  }

  const usage = data.data;
  const buckets = usage.map((d) => new Date(d.bucket).getTime());
  const requests = usage.map((d) => parseInt(d.count));

  const options = {
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "datetime",
      categories: buckets,
    },
    tooltip: {
      x: {
        format: "HH:mm:ss on dd MMM yyyy ",
      },
    },
  };

  const series = [
    {
      name: "Requests",
      data: requests,
    },
  ];

  const totalCount = requests.reduce((a, b) => a + b, 0);

  return (
    <DashboardWidget className={className}>
      <div className="flex items-center justify-between mb-4">
        <div className="flex-shrink-0">
          <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
            {totalCount.toLocaleString()}
          </span>
          <h3 className="text-base font-normal text-gray-500">
            Requests (All Endpoints)
          </h3>
        </div>
        <div className="flex items-center justify-end flex-1 text-green-500 text-base font-bold">
          {/* TODO: At least make this more compellingly fake for now */}
          12.5%
          <i className="bi bi-arrow-up "></i>
        </div>
      </div>
      <div id="main-chart">
        <Chart
          options={options}
          series={series}
          type="area"
          width="100%"
          height="300"
        />
      </div>
    </DashboardWidget>
  );
}

export default RequestsGraph;
