import DashboardWidget from "./DashboardWidget";
import { useQuery } from "react-query";
import { getCustomers, getRequestsByStatus } from "../../util/api";
import Chart from "react-apexcharts";
import { Loader } from "rsuite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function ErrorTableRow({ error, customerId, chartOptions }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const sparkLineOptions = {
    chart: {
      animations: {
        enabled: false,
        animateGradually: {
          enabled: false,
        },
      },
      type: "bar",

      width: 100,
      height: 35,
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      enabled: false,
    },
    fill: {
      colors: ["#cc0000"],
    },
  };

  return (
    <div className="relative ">
      <i
        className={`bi ${
          isExpanded ? "bi-caret-down-fill" : "bi-caret-right-fill"
        }  absolute top-7 left-2`}
      />
      <div
        className={`px-8 py-6 hover:bg-slate-100 hover:cursor-pointer grid gap-4 ${
          customerId ? "grid-cols-6" : "grid-cols-7"
        }`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="text-xl">
          {error.method} {error.path}
        </div>
        <div className="text-xl col-span-2">
          <span className="font-semibold">{error.status}: </span>
          {error.message}
        </div>
        <div className="text-xl">{error.events.reduce((a, b) => a + b, 0)}</div>
        {customerId ? null : (
          <div className="text-xl">{error.customers.length}</div>
        )}
        <div className="text-xl">{error.last_seen} min ago</div>
        <div className="text-xl">
          <Chart
            options={sparkLineOptions}
            series={[{ data: error.events }]}
            type="bar"
            height={24}
            width={100}
          />
        </div>
      </div>
      <div className={`flex flex-row my-12 mx-6 ${isExpanded || "hidden"}`}>
        <div className="flex-1">
          <Chart
            options={chartOptions}
            series={[{ data: error.events }]}
            type="area"
            width="100%"
            height={isExpanded ? 300 : 0}
          />
        </div>
        {customerId ? null : (
          <div className="mx-32 text-lg min-w-max">
            <div className="font-bold text-gray-900 mb-3">
              Affected Customers
            </div>
            <ol>
              {error.customers.map((c) => (
                <li>
                  <Link
                    className="text-blue-800 hover:text-blue-600 hover:no-underline"
                    to={`/dashboard/customers/${c.id}`}
                  >
                    {c.name}
                  </Link>
                </li>
              ))}
            </ol>
          </div>
        )}
      </div>
      <hr />
    </div>
  );
}

function ErrorTable({ className, customerId }) {
  const requestsQuery = useQuery(
    ["requests-all-by-status", customerId],
    getRequestsByStatus(customerId)
  );

  const customerQuery = useQuery("all-customer", getCustomers());
  const [errors, setErrors] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (!(customerQuery.isSuccess && requestsQuery.isSuccess)) {
      return;
    }

    const customers = customerQuery.data.data;

    let TODO_HARD_CODED_ERRORS = [
      {
        status: "404",
        method: "GET",
        path: "/api/dogs/:id",
        message: "The requested resource was not found",
        customers: [1, 3, 4, 5, 7, 8, 9, 11, 14].map((i) =>
          customers.find((x) => x.id == i)
        ),
        last_seen: Math.floor(Math.random() * 60),
        events: [],
      },
      {
        status: "400",
        method: "POST",
        path: "/api/dogs",
        message: "Missing required parameter: Name",
        customers: [2, 4, 6, 7].map((i) => customers.find((x) => x.id == i)),
        last_seen: Math.floor(Math.random() * 60),
        events: [],
      },
      {
        status: "400",
        method: "POST",
        path: "/api/cats",
        message: "The age parameter must be a positive integer",
        customers: [7, 8, 14].map((i) => customers.find((x) => x.id == i)),
        last_seen: Math.floor(Math.random() * 60),
        events: [],
      },
      {
        status: "400",
        method: "PUT",
        path: "/api/dogs",
        message: "A resource with this name already exists",
        customers: [4, 12, 14, 16, 17, 19].map((i) =>
          customers.find((x) => x.id == i)
        ),
        last_seen: Math.floor(Math.random() * 60),
        events: [],
      },
      {
        status: "400",
        method: "POST",
        path: "/api/dogs",
        message: "Details field is not valid JSON",
        customers: [16].map((i) => customers.find((x) => x.id == i)),
        last_seen: Math.floor(Math.random() * 60),
        events: [],
      },
    ];

    const weightedIndices = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 3, 3, 3, 3, 3, 3, 4,
    ];

    // TODO: This is very very inefficient, need to improve.
    const requestData = requestsQuery.data.data;
    let buckets = [...new Set(requestData.map((d) => d.bucket))].sort();
    requestData.forEach((item) => {
      const errorIdx =
        weightedIndices[Math.floor(Math.random() * weightedIndices.length)];
      TODO_HARD_CODED_ERRORS[errorIdx].events[buckets.indexOf(item.bucket)] =
        TODO_HARD_CODED_ERRORS[errorIdx].events[buckets.indexOf(item.bucket)] ||
        0;
      TODO_HARD_CODED_ERRORS[errorIdx].events[buckets.indexOf(item.bucket)] +=
        parseInt(item.count);
    });

    buckets = buckets.map((bucket) => new Date(bucket).getTime());

    // Fill in empty spots in the data with 0
    for (let i = 0; i < buckets.length; i++) {
      for (let j = 0; j < TODO_HARD_CODED_ERRORS.length; j++) {
        if (!TODO_HARD_CODED_ERRORS[j].events[i]) {
          TODO_HARD_CODED_ERRORS[j].events[i] = 0;
        }
      }
    }

    if (customerId) {
      TODO_HARD_CODED_ERRORS = TODO_HARD_CODED_ERRORS.filter((e) => {
        return e.customers.find((c) => c.id == customerId);
      });
    }

    setErrors(TODO_HARD_CODED_ERRORS);
    setChartOptions({
      chart: {
        animations: {
          enabled: false,
          animateGradually: {
            enabled: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        colors: ["#cc0000"],
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      stroke: {
        curve: "smooth",
        colors: ["#cc0000"],
        width: 2,
      },
      xaxis: {
        type: "datetime",
        categories: buckets,
      },
      tooltip: {
        x: {
          format: "HH:mm:ss on dd MMM yyyy ",
        },
      },
    });
  }, [customerQuery.status, requestsQuery.status]);

  if (requestsQuery.isLoading || customerQuery.isLoading) {
    return (
      <DashboardWidget className={className}>
        <Loader size="md" center />
      </DashboardWidget>
    );
  }

  return (
    <DashboardWidget className={className}>
      <div
        className={`px-8 py-1 m-4 grid gap-4 ${
          customerId ? "grid-cols-6" : "grid-cols-7"
        }`}
      >
        <div className="text-xl font-semibold text-gray-900 col-span-1">
          Endpoint
        </div>
        <div className="text-xl font-semibold text-gray-900 col-span-2">
          Response
        </div>
        <div className="text-xl font-semibold text-gray-900 col-span-1">
          Count
        </div>
        {customerId ? null : (
          <div className="text-xl font-semibold text-gray-900 col-span-1">
            # Affected
          </div>
        )}
        <div className="text-xl font-semibold text-gray-900 col-span-1">
          Last Seen
        </div>
        <div className="text-xl font-semibold text-gray-900 col-span-1"></div>
      </div>
      {errors.map((e) => (
        <ErrorTableRow
          error={e}
          key={e.message}
          customerId={customerId}
          chartOptions={chartOptions}
        />
      ))}
    </DashboardWidget>
  );
}

export default ErrorTable;
