import { useQuery, useMutation, useQueryClient } from "react-query";
import { Loader } from "rsuite";
import BillingPlanEntry from "../../components/BillingPlanEntry";
import RevenueByBillingPlan from "../../components/DashboardWidgets/RevenueByBillingPlan";
import CustomersByBillingPlan from "../../components/DashboardWidgets/CustomersByBillingPlan";

import { getBillingPlans, createBillingPlan } from "../../util/api";

function BillingPlans() {
  const queryClient = useQueryClient();

  const { isSuccess, error, isLoading, data } = useQuery(
    "billing-plans-all",
    getBillingPlans
  );

  const createPlanMutation = useMutation(createBillingPlan, {
    onSuccess: () => queryClient.invalidateQueries("billing-plans-all"),
    onError: (err) => console.log(err),
  });

  if (isLoading) {
    return <Loader size="md" center />;
  }

  return (
    <div>
      {isSuccess && (
        <div className="grid grid-cols-2 m-4 gap-4">
          <RevenueByBillingPlan billingPlans={data.data} />
          <CustomersByBillingPlan billingPlans={data.data} />
        </div>
      )}
      {isSuccess &&
        data.data.map((planData) => (
          <BillingPlanEntry planData={planData} key={planData.id} />
        ))}
      {isSuccess && (
        <button
          onClick={() => {
            createPlanMutation.mutate();
          }}
          disabled={createPlanMutation.isLoading}
          className="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center flex-shrink p-4 m-4 px-8 disabled:bg-gray-300"
        >
          <span className="bi bi-plus mr-1"></span>
          Create New Plan
        </button>
      )}
    </div>
  );
}

export default BillingPlans;
