import DashboardDataFilter from "../../components/DashboardDataFilter";
import GraphCreationModal from "../../components/GraphCreationModal";
import RequestsByPathGraph from "../../components/DashboardWidgets/RequestsByPathGraph";
import RequestsByStatusGraph from "../../components/DashboardWidgets/RequestsByStatusGraph";
import RequestsGraph from "../../components/DashboardWidgets/RequestsGraph";
import ResponseCodesChart from "../../components/DashboardWidgets/ResponseCodesChart";
import RevenueByEndpoint from "../../components/DashboardWidgets/RevenueByEndpoint";
import RevenueTotals from "../../components/DashboardWidgets/RevenueTotals";
import CustomChart from "../../components/DashboardWidgets/CustomChart";
import ErrorTable from "../../components/DashboardWidgets/ErrorTable";

const TODO_REMOVE_HARDCODED_TOTAL_REQUESTS = 9225;

function Overview() {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-8 m-4 my-8">
      <div className="col-span-4 font-semibold text-2xl">
        Filter/Modify Data
      </div>
      <div className="col-span-4 flex flex-row">
        <DashboardDataFilter className="flex-grow mr-36" />
        <div>
          <GraphCreationModal />
        </div>
      </div>
      <div className="col-span-4 font-semibold text-2xl">Revenue</div>
      <RevenueTotals className="col-span-1" />
      <RevenueByEndpoint className="col-span-3" />
      <div className="col-span-4 font-semibold text-2xl">Requests</div>
      <RequestsGraph className="col-span-2" />
      <RequestsByPathGraph className="col-span-1" />
      <CustomChart
        className="col-span-1"
        ys={[
          [0.23, 0.77].map((n) =>
            parseInt(n * TODO_REMOVE_HARDCODED_TOTAL_REQUESTS)
          ),
        ]}
        x={["v1", "v2"]}
        title="Requests by API Version"
        type="pie"
      />
      <CustomChart
        className="col-span-1"
        ys={[
          [0.08, 0.09, 0.18, 0.16, 0.17, 0.15, 0.15].map((n) =>
            parseInt(n * TODO_REMOVE_HARDCODED_TOTAL_REQUESTS)
          ),
        ]}
        x={["Sa", "Su", "M", "Tu", "W", "Th", "F"]}
        title="Requests by Day of Week"
        type="bar"
        subtitle=""
      />
      <CustomChart
        className="col-span-2"
        ys={[
          [
            0.01, 0.01, 0.02, 0.025, 0.025, 0.028, 0.03, 0.031, 0.035, 0.037,
            0.031, 0.04, 0.044, 0.046, 0.048, 0.049, 0.051, 0.055, 0.043, 0.041,
            0.038, 0.035, 0.025, 0.014,
          ].map((n) => parseInt(n * TODO_REMOVE_HARDCODED_TOTAL_REQUESTS)),
        ]}
        x={[
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24,
        ]}
        title="Requests by Time of Day"
        type="bar"
        subtitle="24-hour format"
      />
      <CustomChart
        className="col-span-1"
        ys={[
          [0.32, 0.6, 0.08].map((n) =>
            parseInt(n * TODO_REMOVE_HARDCODED_TOTAL_REQUESTS)
          ),
        ]}
        x={["SDK v1", "SDK v2", "SDK v2.1"]}
        title="Requests by SDK Version"
        type="pie"
      />
      <div className="col-span-4 font-semibold text-2xl">Responses</div>
      <RequestsByStatusGraph className="col-span-3" />
      <ResponseCodesChart className="col-span-1" />
      <div className="col-span-4 font-semibold text-2xl">Recent Errors</div>
      <ErrorTable className="col-span-4" />
    </div>
  );
}

export default Overview;
