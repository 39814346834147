import DashboardWidget from "./DashboardWidget";
import { useQuery } from "react-query";
import { getResponseCodes } from "../../util/api";
import Chart from "react-apexcharts";
import { Loader } from "rsuite";
import { TODO_FAKE_REVENUE_PER_CALL } from "../../util/constants";

const blues = ["0277BD", "0288D1", "1E88E5", "2196F3", "42A5F5", "29B6F6"];
const yellows = ["F9A825", "FBC02D", "FDD835", "FFEB3B", "FFCA28"];
const reds = ["D84315", "E64A19", "F4511E", "FF5722", "FF7043"];

function RevenueByBillingPlan({ className, customerId, billingPlans }) {
  const { isLoading, error, data } = useQuery(
    ["response-codes-all", customerId],
    getResponseCodes(customerId)
  );

  if (isLoading || !billingPlans) {
    return (
      <DashboardWidget className={className}>
        <Loader size="md" center />
      </DashboardWidget>
    );
  }

  if (error) {
    console.log(error);
  }

  const usage = data.data;
  const requests = usage
    .map((d) => parseInt(d.count))
    .reduce((a, b) => a + b, 0);

  // TODO: Make these values all real
  const revenueMTD = requests * TODO_FAKE_REVENUE_PER_CALL;
  const TODO_PLAN_PERCENTAGES = [0.23, 0.46, 0.31];
  let plans = [];
  let revenue = [];
  billingPlans.forEach((plan, i) => {
    plans.push(plan.name);
    revenue.push(i < 3 ? TODO_PLAN_PERCENTAGES[i] * revenueMTD : 0);
  });

  const options = {
    labels: plans,
    tooltip: {
      y: {
        formatter: function (val, opt) {
          return "$" + val;
        },
      },
    },
  };

  return (
    <DashboardWidget className={`${className} flex flex-col`}>
      <div className="flex items-center justify-between mb-4">
        <div className="flex-shrink-0">
          <h3 className="text-xl font-bold text-gray-900 mb-2">
            Revenue By Billing Plan
          </h3>
          <span className="text-base font-normal text-gray-500">
            Month-to-Date
          </span>
        </div>
      </div>
      <div id="main-chart" className="flex-grow flex">
        <Chart
          options={options}
          series={revenue}
          type="pie"
          width="100%"
          height="200"
          className="flex-grow flex justify-center flex-col"
        />
      </div>
    </DashboardWidget>
  );
}

export default RevenueByBillingPlan;
