import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { getCustomers } from "../util/api";

// TODO: Make all these options dynamic

const endpointOptions = [
  { value: "/api/dogs", label: "/api/dogs" },
  { value: "/api/dogs/:dogId", label: "/api/dogs/:dogId" },
  { value: "/api/dogs/foster", label: "/api/dogs/foster" },
  { value: "/api/dogs/purchase", label: "/api/dogs/purchase" },
  { value: "/api/cats", label: "/api/cats" },
  { value: "/api/cats/:catId", label: "/api/cats/:catId" },
];

const responseStatusOptions = [
  { value: "200", label: "200 (OK)" },
  { value: "400", label: "400 (Bad Request)" },
  { value: "401", label: "401 (Unauthorized)" },
  { value: "403", label: "403 (Forbidden)" },
  { value: "404", label: "404 (Not Found)" },
  { value: "500", label: "500 (Internal Server Error)" },
];

const routePrefixOptions = [
  { value: "/api/", label: "/api/" },
  { value: "/api/dogs/", label: "/api/dogs/" },
  { value: "/api/cats/", label: "/api/cats/" },
  { value: "/api/v2/", label: "/api/v2/" },
];

function DashboardDataFilter({ className }) {
  const customerQuery = useQuery("all-customer", getCustomers());
  const [billingPlanOptions, setBillingPlanOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);

  // When customerQuery is done loading, set the customerOptions
  useEffect(() => {
    if (customerQuery.status === "success") {
      const newCustomerOptions = customerQuery.data.data.map((customer) => ({
        value: customer.foreign_id,
        label: customer.name,
      }));
      const newBillingPlanOptions = [
        ...new Set(
          customerQuery.data.data.map((customer) => customer.billing_plan_name)
        ),
      ]
        .filter((bp) => bp)
        .map((bp) => ({ value: bp, label: bp }));
      setCustomerOptions(newCustomerOptions);
      setBillingPlanOptions(newBillingPlanOptions);
    }
  }, [customerQuery.status]);

  return (
    <div className={className}>
      <div className="grid grid-cols-3 gap-4">
        <div className="">
          <label className="font-semibold  text-gray-600 block">
            Billing Plan
          </label>
          <Select
            isMulti
            classNamePrefix="react-select"
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            isClearable={true}
            options={billingPlanOptions}
            placeholder="All Plans"
          />
        </div>
        <div className="">
          <label className="font-semibold  text-gray-600 block">Customer</label>
          <Select
            isMulti
            classNamePrefix="react-select"
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            options={customerOptions}
            placeholder="All Customers"
          />
        </div>
        <div className="">
          <label className="font-semibold  text-gray-600 block">Endpoint</label>
          <Select
            isMulti
            classNamePrefix="react-select"
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            options={endpointOptions}
            placeholder="All Endpoints"
          />
        </div>
        <div className="">
          <label className="font-semibold  text-gray-600 block">
            Response Status
          </label>
          <Select
            isMulti
            classNamePrefix="react-select"
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            options={responseStatusOptions}
            placeholder="All Statuses"
          />
        </div>
        <div className="">
          <label className="font-semibold  text-gray-600 block">
            Route Prefix
          </label>
          <CreatableSelect
            isMulti
            classNamePrefix="react-select"
            formatCreateLabel={(inputValue) => `${inputValue}`}
            createOptionPosition="first"
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            options={routePrefixOptions}
            placeholder="None"
          />
        </div>
        <div className="">
          <button
            onClick={() => {}}
            className="transition duration-200 bg-gray-300 hover:bg-gray-400 focus:bg-gray-400 focus:shadow-sm focus:ring-4 focus:ring-gray-400 focus:ring-opacity-50 text-black rounded-md text-md shadow-sm hover:shadow-md font-semibold text-center flex-shrink p-2 m-2 mt-5 disabled:bg-gray-100"
          >
            Apply Filters
          </button>
        </div>
      </div>
    </div>
  );
}

export default DashboardDataFilter;
