import axios from "axios";

const BASE_API_URL = "https://raio-6i9mcc8z.wl.gateway.dev";

export const getUsage = (customer) => () =>
  axios.get(
    `${BASE_API_URL}/usage?bucket=second${
      customer ? `&customer=${customer}` : ""
    }`
  );

export const getResponseCodes = (customer) => () =>
  axios.get(
    `${BASE_API_URL}/usage?bucket=millennium&groupby=status${
      customer ? `&customer=${customer}` : ""
    }`
  );

export const getRequestsByPath = (customer) => () =>
  axios.get(
    `${BASE_API_URL}/usage?bucket=second&groupby=path${
      customer ? `&customer=${customer}` : ""
    }`
  );

export const getRequestsByStatus = (customer) => () =>
  axios.get(
    `${BASE_API_URL}/usage?bucket=second&groupby=status${
      customer ? `&customer=${customer}` : ""
    }`
  );

export const getRequestsByCustomer = () => () =>
  axios.get(BASE_API_URL + "/usage?bucket=second&groupby=customer_id");

export const getCustomers = () => () => axios.get(BASE_API_URL + "/customers");

export const updateCustomer = ({ id, data }) =>
  axios.put(BASE_API_URL + `/customers/${id}`, data);

export const getBillingPlans = () => axios.get(BASE_API_URL + "/billing-plans");

export const createBillingPlan = () =>
  axios.post(BASE_API_URL + "/billing-plans", { name: "New Plan", plan: {} });

export const updateBillingPlan = ({ id, data }) =>
  axios.put(BASE_API_URL + `/billing-plans/${id}`, data);

export const TODO_FIX_PATH_NAME = (path) =>
  path.replace("1000", "purchase").replace("1", "foster");

export const getNotifications = () => [
  {
    customer_id: 2,
    customer_name: "Givester",
    message: "had a 120% increase in requests in the past hour.",
    timestamp: "16 minutes ago",
    code: "yellow",
  },
  {
    customer_id: 12,
    customer_name: "Snor",
    message: "made their first request!",
    timestamp: "32 minutes ago",
    code: "green",
  },

  {
    customer_id: 4,
    customer_name: "Frigital",
    message: "had a spike in 403 (Forbidden) responses during the past hour.",
    timestamp: "41 minutes ago",
    code: "red",
  },
  {
    customer_id: 5,
    customer_name: "BatIQ",
    message: "had a 50% decrease in requests in the past hour.",
    timestamp: "48 minutes ago",
    code: "red",
  },
];
