import { data } from "autoprefixer";
import { useState } from "react";
import Select, { components as selectComponents } from "react-select";
import CreatableSelect from "react-select/creatable";

const Option = ({ children, ...props }) => (
  <selectComponents.Option {...props}>
    <div className="flex flex-row items-center content-center">
      {props.data.value === "remove" ? (
        <span className="text-red-500 font-semibold">{props.data.label}</span>
      ) : (
        props.data.option ?? props.data.label
      )}
      {props.data.tag ? (
        <div
          className={`text-[0.5rem] leading-[0.6rem] text-white font-semibold rounded-lg self-center p-1 ml-2 ${
            {
              endpoint: "bg-orange-500",
              unit: "bg-yellow-500",
              payload: "bg-violet-500",
            }[props.data.tag]
          }`}
        >
          {props.data.tag}
        </div>
      ) : null}
    </div>
  </selectComponents.Option>
);

const ValueTypeMenuList = ({ children, ...props }) => {
  return (
    <selectComponents.MenuList {...props}>
      <div className="p-2 mb-2 italic text-gray-400 text-sm bg-slate-100">
        Enter a numerical value or select an option below:
      </div>
      {children}
    </selectComponents.MenuList>
  );
};

const CostMenuList = ({ children, ...props }) => {
  return (
    <selectComponents.MenuList {...props}>
      <div className="p-2 mb-2 italic text-gray-400 text-sm bg-slate-100">
        Enter a new cost or select <br /> a previously-used cost below:
      </div>
      {children}
    </selectComponents.MenuList>
  );
};

const VALUE_TYPES = [
  {
    value: "total_requests",
    label: "total # of requests",
    option: "Total # of Requests to...",
  },
  {
    value: "average_requests",
    label: "average # of requests",
    option: "Average # of Requests per...",
  },
  { value: "count", label: "number", option: "Number of..." },
  {
    value: "average_count",
    label: "average number",
    option: "Average number per...",
  },
  {
    value: "sum",
    label: "sum",
    option: "Sum of all values of...",
  },
  {
    value: "mean",
    label: "average (mean)",
    option: "Average (mean) of all values of...",
  },
  {
    value: "max",
    label: "Maximum",
    option: "Maximum of all values of...",
  },
  {
    value: "min",
    label: "Minimum",
    option: "Minimum of all values of...",
  },
  //   {
  //     value: "percentile",
  //     label: "percentile",
  //     option: "Nth Percentile of...",
  //   },
  {
    value: "total",
    label: "total invoice amount",
    option: "Total Invoice Amount",
  },
  {
    value: "credits_used",
    label: "credits used",
    option: "Credits Used",
  },
];

const CLAUSE_JOINERS = [
  { value: "and", label: "and" },
  { value: "or", label: "or" },
  { value: "remove", label: "Remove" },
];

const VALUE_JOINERS = [
  { value: "+", label: "+" },
  { value: "-", label: "-" },
  { value: "*", label: "*" },
  { value: "/", label: "/" },
  { value: "remove", label: "Remove" },
];

const COMPARATORS = [
  { value: ">", label: ">" },
  { value: "≥", label: "≥" },
  { value: "<", label: "<" },
  { value: "≤", label: "≤" },
  { value: "=", label: "=" },
  { value: "≠", label: "≠" },
];

const CURRENCIES = [
  { value: "usd", label: "USD" },
  { value: "credits", label: "credits" },
];

const TODO_REPLACE_ROUTE_OPTIONS = [
  { value: "/api/dogs", label: "/api/dogs", tag: "endpoint" },
  { value: "/api/dogs/:dogId", label: "/api/dogs/:dogId", tag: "endpoint" },
  { value: "/api/dogs/foster", label: "/api/dogs/foster", tag: "endpoint" },
  { value: "/api/dogs/purchase", label: "/api/dogs/purchase", tag: "endpoint" },
  { value: "/api/cats", label: "/api/cats", tag: "endpoint" },
  { value: "/api/cats/:catId", label: "/api/cats/:catId", tag: "endpoint" },
];

const TODO_REPLACE_METRIC_OPTIONS = [
  { value: "OBJECT_CREATED", label: "OBJECT_CREATED", tag: "unit" },
  { value: "IMAGE_UPLOADED", label: "IMAGE_UPLOADED", tag: "unit" },
  { value: "PROCESSING_TIME", label: "PROCESSING_TIME", tag: "unit" },
  { value: "retention", label: "retention", tag: "payload" },
  { value: "replicas", label: "replicas", tag: "payload" },
];

const TIME_SPANS = [
  { value: "minute", label: "minute" },
  { value: "hour", label: "hour" },
  { value: "day", label: "day" },
  { value: "week", label: "week" },
  { value: "month", label: "month" },
];

const selectStyles = {
  container: (provided) => ({
    ...provided,
    display: "inline-block",
    "margin-bottom": "0.5rem",
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  control: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
};

const seperatorSelectStyles = {
  container: (provided) => ({
    ...provided,
    display: "inline-block",
    "margin-bottom": "0.5rem",
    "margin-left": "0.25rem",
    "margin-right": "0.25rem",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#3b82f6",
    cursor: "pointer",
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
};

function ValueInput({ value, onChange, allowFixed }) {
  function renderTypeSpecificInputs(component, index) {
    if (!component.type) return null;
    switch (component.type.value) {
      case "total_requests":
        return (
          <>
            {" to "}
            <Select
              placeholder="Endpoint..."
              classNamePrefix="react-select"
              components={{ Option }}
              value={component.parameters.endpoint}
              onChange={(newVal) => {
                value.components[index].parameters.endpoint = newVal;
                onChange(value);
              }}
              styles={selectStyles}
              options={TODO_REPLACE_ROUTE_OPTIONS}
            />
          </>
        );
      case "average_requests":
        return (
          <>
            {" to "}
            <Select
              placeholder="Endpoint..."
              classNamePrefix="react-select"
              components={{ Option }}
              value={component.parameters.endpoint}
              onChange={(newVal) => {
                value.components[index].parameters.endpoint = newVal;
                onChange(value);
              }}
              styles={selectStyles}
              options={TODO_REPLACE_ROUTE_OPTIONS}
            />
            {" per "}
            <Select
              placeholder="Period..."
              classNamePrefix="react-select"
              value={component.parameters.span}
              onChange={(newVal) => {
                value.components[index].parameters.span = newVal;
                onChange(value);
              }}
              components={{ Option }}
              styles={selectStyles}
              options={TIME_SPANS}
            />
          </>
        );
      case "count":
        return (
          <>
            {" of "}
            <Select
              placeholder="Metric..."
              classNamePrefix="react-select"
              components={{ Option }}
              value={component.parameters.metric}
              onChange={(newVal) => {
                value.components[index].parameters.metric = newVal;
                onChange(value);
              }}
              styles={selectStyles}
              options={TODO_REPLACE_METRIC_OPTIONS}
            />
            {" records "}
          </>
        );
      case "average_count":
        return (
          <>
            {" of "}
            <Select
              placeholder="Metric..."
              classNamePrefix="react-select"
              components={{ Option }}
              value={component.parameters.metric}
              onChange={(newVal) => {
                value.components[index].parameters.metric = newVal;
                onChange(value);
              }}
              styles={selectStyles}
              options={TODO_REPLACE_METRIC_OPTIONS}
            />
            {" records per "}
            <Select
              placeholder="Period..."
              classNamePrefix="react-select"
              components={{ Option }}
              value={component.parameters.span}
              onChange={(newVal) => {
                value.components[index].parameters.span = newVal;
                onChange(value);
              }}
              styles={selectStyles}
              options={TIME_SPANS}
            />
          </>
        );
      case "sum":
        return (
          <>
            {" of "}
            <Select
              placeholder="Metric..."
              classNamePrefix="react-select"
              components={{ Option }}
              value={component.parameters.metric}
              onChange={(newVal) => {
                value.components[index].parameters.metric = newVal;
                onChange(value);
              }}
              styles={selectStyles}
              options={TODO_REPLACE_METRIC_OPTIONS}
            />
            {"'s values "}
          </>
        );
      case "mean":
        return (
          <>
            {" of "}
            <Select
              placeholder="Metric..."
              classNamePrefix="react-select"
              components={{ Option }}
              value={component.parameters.metric}
              onChange={(newVal) => {
                value.components[index].parameters.metric = newVal;
                onChange(value);
              }}
              styles={selectStyles}
              options={TODO_REPLACE_METRIC_OPTIONS}
            />
            {"'s values "}
          </>
        );
      case "max":
        return (
          <>
            {" of "}
            <Select
              placeholder="Metric..."
              classNamePrefix="react-select"
              components={{ Option }}
              value={component.parameters.metric}
              onChange={(newVal) => {
                value.components[index].parameters.metric = newVal;
                onChange(value);
              }}
              styles={selectStyles}
              options={TODO_REPLACE_METRIC_OPTIONS}
            />
            {"'s values "}
          </>
        );
      case "min":
        return (
          <>
            {" of "}
            <Select
              placeholder="Metric..."
              classNamePrefix="react-select"
              components={{ Option }}
              value={component.parameters.metric}
              onChange={(newVal) => {
                value.components[index].parameters.metric = newVal;
                onChange(value);
              }}
              styles={selectStyles}
              options={TODO_REPLACE_METRIC_OPTIONS}
            />
            {"'s values "}
          </>
        );
      //   case "percentile":
      //     return (
      //       <>

      //       </>
      //     );
      default:
        break;
    }
  }

  return (
    <span className="rounded-md bg-blue-50 p-2 m-1 pb-0 inline-block group ">
      {value.components.map((component, index) => (
        <>
          {component.joiner ? (
            <Select
              classNamePrefix="react-select"
              styles={{ ...selectStyles, ...seperatorSelectStyles }}
              value={component.joiner}
              components={{ Option }}
              onChange={(v) => {
                if (v.value === "remove") {
                  value.components.splice(index, 1);
                  onChange(value);
                } else {
                  value.components[index].joiner = v;
                  onChange(value);
                }
              }}
              options={VALUE_JOINERS}
            />
          ) : null}
          <CreatableSelect
            formatCreateLabel={(inputValue) => `Use value ${inputValue}`}
            isValidNewOption={(inputValue) =>
              /^[\-]?[0-9]+[.0-9]*$/.test(inputValue)
            }
            classNamePrefix="react-select"
            placeholder="Value..."
            styles={selectStyles}
            components={{ Option, MenuList: ValueTypeMenuList }}
            onChange={(v) => {
              value.components[index].type = v;
              onChange(value);
            }}
            value={component.type}
            options={
              allowFixed && index === 0
                ? [
                    ...VALUE_TYPES,
                    {
                      value: "fixed",
                      label: "fixed cost:",
                      option: "Fixed Cost:",
                    },
                  ]
                : VALUE_TYPES
            }
          />
          {renderTypeSpecificInputs(component, index)}
        </>
      ))}
      {value.components[0].type &&
      value.components[0].type.value === "fixed" ? null : (
        <span
          onClick={() => {
            value.components.push({
              joiner: VALUE_JOINERS[0],
              type: null,
              parameters: {},
            });
            onChange(value);
          }}
          className="p-1 m-1 rounded-sm bg-gray-300 bg-opacity-50 opacity-40 group-hover:inline hover:opacity-100 hover:bg-opacity-70 cursor-pointer"
        >
          <i className="bi bi-plus"></i>
        </span>
      )}
    </span>
  );
}

function CostInput({ value, onChange }) {
  return (
    <span className="rounded-md bg-green-50 p-2 m-1 pb-0 inline-block ">
      <CreatableSelect
        classNamePrefix="react-select"
        placeholder="Cost..."
        styles={selectStyles}
        formatCreateLabel={(inputValue) =>
          `Set cost: ${inputValue} ${value.unit ? value.unit.label : ""}`
        }
        isValidNewOption={(inputValue) =>
          /^[\-]?[0-9]+[.0-9]*$/.test(inputValue)
        }
        value={value.number}
        onChange={(v) => {
          value.number = v;
          onChange(value);
        }}
        components={{ MenuList: CostMenuList }}
        options={[]}
      />
      <Select
        classNamePrefix="react-select"
        styles={selectStyles}
        value={value.unit}
        placeholder="Unit..."
        onChange={(v) => {
          value.unit = v;
          onChange(value);
        }}
        options={CURRENCIES}
      />
    </span>
  );
}

function BillingPlanEditor({ planDetails, onPlanDetailsChange }) {
  const rules = planDetails.rules ?? [];

  function setRules(newRules) {
    onPlanDetailsChange({ ...planDetails, rules: newRules });
  }

  function createNewRule() {
    return {
      conditions: [],
      amount: {
        value: {
          components: [{ joiner: null, type: null, parameters: {} }],
        },
        cost: { number: null, unit: CURRENCIES[0] },
      },
      description: "",
    };
  }

  function addCondition(rule, joiner) {
    rule.conditions.push({
      lhs: {
        components: [{ joiner: null, type: null, parameters: {} }],
      },
      rhs: {
        components: [{ joiner: null, type: null, parameters: {} }],
      },
      comparator: COMPARATORS[0],
      joiner: joiner,
    });
    return rule;
  }

  function renderNewRuleButtons() {
    return (
      <>
        <div>
          <button
            onClick={() => setRules([...rules, createNewRule()])}
            className="transition duration-200 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 text-white rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center flex-shrink py-2 px-4 mx-2 disabled:bg-gray-300"
          >
            Add Amount
          </button>
          <span className="italic">or</span>
          <button
            onClick={() => setRules([...rules, addCondition(createNewRule())])}
            className="transition duration-200 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 text-white rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center flex-shrink py-2 px-4 mx-2 disabled:bg-gray-300"
          >
            Add Condition
          </button>
        </div>
        <div></div>
      </>
    );
  }

  function renderRule(rule, ruleIdx) {
    return (
      <>
        <div key={ruleIdx}>
          {rule.conditions.map((condition, index) => (
            <div key={index}>
              {condition.joiner ? (
                <Select
                  classNamePrefix="react-select"
                  styles={{ ...selectStyles, ...seperatorSelectStyles }}
                  value={condition.joiner}
                  components={{ Option }}
                  onChange={(v) => {
                    if (v.value === "remove") {
                      rules[ruleIdx].conditions.splice(index, 1);
                      setRules([...rules]);
                    } else {
                      rules[ruleIdx].conditions[index].joiner = v;
                      setRules([...rules]);
                    }
                  }}
                  options={CLAUSE_JOINERS}
                />
              ) : (
                <span className="font-semibold">If</span>
              )}{" "}
              <ValueInput
                value={condition.lhs}
                onChange={(newValue) => {
                  rules[ruleIdx].conditions[index].lhs = newValue;
                  setRules([...rules]);
                }}
              />{" "}
              <Select
                classNamePrefix="react-select"
                styles={selectStyles}
                value={condition.comparator}
                onChange={(newValue) => {
                  rules[ruleIdx].conditions[index].comparator = newValue;
                  setRules([...rules]);
                }}
                options={COMPARATORS}
              />
              <ValueInput
                value={condition.rhs}
                onChange={(newValue) => {
                  rules[ruleIdx].conditions[index].rhs = newValue;
                  setRules([...rules]);
                }}
              />
              {index === rule.conditions.length - 1 ? (
                <span
                  onClick={() => {
                    rules[ruleIdx] = addCondition(
                      rules[ruleIdx],
                      CLAUSE_JOINERS[0]
                    );
                    setRules(rules);
                  }}
                  className="p-1 m-1 rounded-sm bg-gray-300 opacity-30 hover:opacity-100 hover:bg-opacity-70 cursor-pointer"
                >
                  <i className="bi bi-plus"></i>
                </span>
              ) : null}
            </div>
          ))}
          <span className="inline-block">
            {rule.conditions.length > 0 ? (
              <span className="font-semibold">then</span>
            ) : null}
            <ValueInput
              value={rule.amount.value}
              onChange={(newValue) => {
                rules[ruleIdx].amount.value = newValue;
                setRules([...rules]);
              }}
              allowFixed
            />
            {rules[ruleIdx].amount.value.components[0].type &&
            rules[ruleIdx].amount.value.components[0].type.value ===
              "fixed" ? null : (
              <span className="bi bi-x-lg" />
            )}
            <CostInput
              value={rule.amount.cost}
              onChange={(newValue) => {
                rules[ruleIdx].amount.cost = newValue;
                setRules([...rules]);
              }}
            />
          </span>
        </div>
        <div>
          <input
            type="text"
            placeholder="Description"
            value={rule.description}
            onChange={(e) => {
              rules[ruleIdx].description = e.target.value;
              setRules(rules);
            }}
            className="form-input rounded-md px-4 border border-gray-200 focus:border-blue-700 mt-4 ml-4 w-full italic placeholder-zinc-400"
          />
        </div>
        <div>
          <button
            onClick={() => {
              rules.splice(ruleIdx, 1);
              setRules(rules);
            }}
            className="rounded-full w-10 h-10 bg-red-50 hover:bg-red-100 transition duration-200 mt-4"
          >
            <span className="bi bi-trash" />
          </button>
        </div>
      </>
    );
  }

  return (
    <div className="my-4">
      <div className="text-lg text-gray-900 m-4 flex flex-row items-end ">
        <span className="bi bi-calendar-week mr-4 text-4xl" />
        <div className="px-6">
          <label className="font-semibold  text-gray-600 block">Period</label>
          <select
            className="form-input border-gray-300 rounded-md"
            value={planDetails.period}
            onChange={(e) =>
              onPlanDetailsChange({ ...planDetails, period: e.target.value })
            }
          >
            <option value="monthly">Monthly</option>
            <option value="weekly">Weekly</option>
            <option value="daily">Daily</option>
            <option value="yearly">Yearly</option>
            <option value="custom">Custom Period</option>
          </select>
          {planDetails.period === "custom" && (
            <span className="inline-block ml-3">
              Every{" "}
              <input
                type="number"
                className="form-input border-gray-300 rounded-md w-20"
                value={planDetails.period_length || 0}
                onChange={(e) =>
                  onPlanDetailsChange({
                    ...planDetails,
                    period_length: e.target.value,
                  })
                }
              />{" "}
              Days
            </span>
          )}
        </div>
        <div className="px-6">
          <label className="font-semibold  text-gray-600 block">
            Start Date
          </label>
          <input
            type="date"
            className="form-input border-gray-300 rounded-md"
            value={
              planDetails.start_date || new Date().toISOString().slice(0, 10)
            }
            onChange={(e) =>
              onPlanDetailsChange({
                ...planDetails,
                start_date: e.target.value,
              })
            }
          />
        </div>
        <div className="px-6">
          <label className="font-semibold  text-gray-600 block">
            End Date (optional)
          </label>
          <input
            type="date"
            value={planDetails.end_date}
            onChange={(e) =>
              onPlanDetailsChange({
                ...planDetails,
                end_date: e.target.value,
              })
            }
            className={`form-input border-gray-300 rounded-md ${
              planDetails.end_date || "text-gray-400"
            }`}
          />
        </div>
      </div>
      <div className="shadow-inner relative border-blue-500 border rounded-md rounded-tr-none bg-white m-4 mt-12 px-4 py-8">
        <div className="absolute top-[-1.5rem] h-[1.5rem] bg-blue-500 rounded-t-md text-white font-semibold px-2 right-[-1px] flex items-center">
          Pricing Structure
        </div>
        <div className="grid grid-cols-[10rem_1fr_12rem_4rem] gap-x-6 gap-y-6 text-lg">
          <div
            className={`font-semibold text-gray-900 text-right ${
              rules.length > 0 ? "py-4" : "self-center"
            }`}
          >
            Invoice Amount =
          </div>
          {rules.length > 0 ? renderRule(rules[0], 0) : renderNewRuleButtons()}
          {rules.length > 1 &&
            rules.slice(1).map((rule, index) => (
              <>
                <div className="text-2xl transition duration-200 font-semibold text-gray-900 text-right py-4">
                  +
                </div>
                {renderRule(rule, index + 1)}
              </>
            ))}
          {rules.length > 0 && (
            <div className="contents group">
              <div className="text-2xl transition duration-200 font-semibold text-gray-900 text-right self-center opacity-30 group-hover:opacity-100">
                +
              </div>
              <div className="transition duration-200 opacity-90 group-hover:opacity-100">
                {renderNewRuleButtons()}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BillingPlanEditor;
