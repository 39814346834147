import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  getCustomers,
  getNotifications,
  getRequestsByCustomer,
} from "../../util/api";
import Chart from "react-apexcharts";
import { Loader } from "rsuite";
import { useState } from "react";
import { TODO_FAKE_REVENUE_PER_CALL } from "../../util/constants";

function Customers() {
  const usageQuery = useQuery("usage-all-by-customer", getRequestsByCustomer());

  const customerQuery = useQuery("all-customer", getCustomers());

  const notifications = getNotifications();

  const [filterBillingPlan, setFilterBillingPlan] = useState("");

  if (usageQuery.isLoading || customerQuery.isLoading) {
    return <Loader size="md" center />;
  }

  //TODO: Move all the calculations in this component and others into an effect

  let customerEntries = customerQuery.data.data.map((customer) => [
    customer.foreign_id,
    {
      ...customer,
      usage: [],
      notifications: notifications.filter((n) => n.customer_id === customer.id),
    },
  ]);

  if (filterBillingPlan) {
    customerEntries = customerEntries.filter(
      ([id, customer]) => customer.billing_plan_name === filterBillingPlan
    );
  }

  let customers = Object.fromEntries(customerEntries);

  let billingPlanOptions = [
    ...new Set(
      customerQuery.data.data.map((customer) => customer.billing_plan_name)
    ),
  ];

  usageQuery.data.data.forEach((stat) => {
    if (customers[stat.customer_id]) {
      customers[stat.customer_id].usage.push(parseInt(stat.count));
    }
  });

  const sparkLineOptions = {
    chart: {
      animations: {
        enabled: false,
        animateGradually: {
          enabled: false,
        },
      },
      type: "bar",
      width: 100,
      height: 35,
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  // TODO: Make this real.
  let nextInvoiceDate = new Date();
  nextInvoiceDate.setDate(nextInvoiceDate.getDate() + 12);
  return (
    <div>
      <div className="p-4">
        <label className="font-semibold  text-gray-600 block">
          Billing Plan
        </label>
        <div className="flex flex-row">
          <select
            className="form-input border-gray-300 rounded-md"
            value={filterBillingPlan}
            onChange={(e) => {
              setFilterBillingPlan(e.target.value);
            }}
          >
            <option value={""}>All</option>
            {billingPlanOptions.map(
              (plan) =>
                plan && (
                  <option value={plan} key={plan}>
                    {plan}
                  </option>
                )
            )}
          </select>
          <div className="flex-grow" />
          <button
            className="transition duration-200 bg-gray-300 hover:bg-gray-400 focus:bg-gray-400 text-black rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center px-4 py-2 ml-4"
            onClick={() => {}}
          >
            <span className="bi bi-download mr-2"></span>
            Export Billing Report (.csv)
          </button>
        </div>
      </div>
      <div className="px-8 py-1 m-4 grid grid-cols-6">
        <div className="text-center text-xl font-semibold">Name</div>
        <div className="text-center text-xl font-semibold">ID</div>
        <div className="text-center text-xl font-semibold">Billing Plan</div>
        <div className="text-center text-xl font-semibold">
          Est. Revenue (MTD)
        </div>
        <div className="text-center text-xl font-semibold">Next Invoice</div>
        <div className="text-center text-xl font-semibold">Usage</div>
      </div>
      {Object.values(customers).map((customer) => (
        <Link
          to={`${customer.id}`}
          className={`relative hover:no-underline grid grid-cols-6 shadow rounded-lg p-4 sm:p-6 xl:p-8 m-4 hover:shadow-lg  ${
            customer.notifications.length
              ? {
                  yellow:
                    "border border-yellow-600 bg-yellow-50 hover:bg-yellow-100",
                  green:
                    "border border-green-600 bg-green-50 hover-bg-green-100",
                  red: "border border-red-600 bg-red-50 hover:bg-red-100",
                }[customer.notifications[0].code]
              : "bg-white hover:bg-slate-100"
          }`}
        >
          {customer.notifications.length ? (
            <i
              className={`absolute left-2 top-1 text-lg bi ${
                {
                  yellow: "bi-exclamation-circle-fill text-yellow-500",
                  green: "bi-check-circle-fill text-green-500",
                  red: "bi-exclamation-circle-fill text-red-500",
                }[customer.notifications[0].code]
              }`}
            />
          ) : null}
          <h3 className="text-center text-xl font-bold text-gray-900">
            {customer.name}
          </h3>
          <div className="text-center text-gray-500 text-xl ml-2">
            {customer.foreign_id}
          </div>
          <div
            className={`text-center ml-2 text-xl ${
              customer.billing_plan_name
                ? "text-gray-500"
                : "italic text-gray-300"
            }`}
          >
            {customer.billing_plan_name || "No Plan Set"}
          </div>
          <div
            className={`text-center ml-2 text-xl ${
              customer.billing_plan_name
                ? "text-gray-500"
                : "italic text-gray-300"
            }`}
          >
            {customer.billing_plan_name
              ? `$${parseInt(
                  customer.usage.reduce((a, b) => a + b, 0) *
                    TODO_FAKE_REVENUE_PER_CALL
                )}`
              : "N/A"}
          </div>
          <div
            className={`text-center ml-2 text-xl ${
              customer.billing_plan_name
                ? "text-gray-500"
                : "italic text-gray-300"
            }`}
          >
            {customer.billing_plan_name
              ? nextInvoiceDate.toLocaleDateString()
              : "None Scheduled"}
          </div>
          <div className="flex flex-row justify-center items-center">
            <Chart
              options={sparkLineOptions}
              series={[{ data: customer.usage }]}
              type="bar"
              height={35}
              width={100}
            />
          </div>
        </Link>
      ))}
    </div>
  );
}

export default Customers;
