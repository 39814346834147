import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useParams } from "react-router-dom";
import { Loader } from "rsuite";
import CustomChart from "../../components/DashboardWidgets/CustomChart";
import ErrorTable from "../../components/DashboardWidgets/ErrorTable";
import RequestsByPathGraph from "../../components/DashboardWidgets/RequestsByPathGraph";
import RequestsByStatusGraph from "../../components/DashboardWidgets/RequestsByStatusGraph";
import RequestsGraph from "../../components/DashboardWidgets/RequestsGraph";
import ResponseCodesChart from "../../components/DashboardWidgets/ResponseCodesChart";
import RevenueByEndpoint from "../../components/DashboardWidgets/RevenueByEndpoint";
import RevenueTotals from "../../components/DashboardWidgets/RevenueTotals";
import {
  getBillingPlans,
  getCustomers,
  getNotifications,
  updateCustomer,
} from "../../util/api";

const TODO_REMOVE_HARDCODED_TOTAL_REQUESTS = 9225 / 20;

function CustomerOverview() {
  let { customerId } = useParams();
  const queryClient = useQueryClient();
  const customerQuery = useQuery("all-customers", getCustomers());
  const billingPlansQuery = useQuery("billing-plans-all", getBillingPlans);
  const updateCustomerMutation = useMutation(updateCustomer, {
    onSuccess: () => {
      console.log("Customer updated");
      customerQuery.remove();
      customerQuery.refetch();
    },
    onError: (err) => console.log(err),
  });
  const [billingPlan, setBillingPlan] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (customerQuery.isSuccess) {
      const newCustomer = customerQuery.data.data.find(
        (c) => c.id.toString() === customerId
      );

      setCustomer(newCustomer);
      setNotifications(
        getNotifications().filter((n) => n.customer_id === newCustomer.id)
      );
      setBillingPlan(newCustomer.billing_plan);
    } else if (customerQuery.isLoading) {
      setCustomer(null);
      setBillingPlan(null);
    }
  }, [customerQuery.status, customerId]);

  if (!customer || billingPlansQuery.isLoading) {
    return <Loader size="md" center />;
  }

  return (
    <>
      <div className="text-4xl mx-4 my-4 font-semibold flex flex-row items-center">
        <Link
          to={`/dashboard/customers`}
          className="mx-4 hover:bg-gray-300 p-2 rounded-full"
        >
          <span className="bi bi-arrow-left" />
        </Link>
        {customer.name}
        {notifications.map((n) => (
          <div
            className={`p-4 m-6 text-sm text-gray-700 ${
              {
                yellow: "border-yellow-600 bg-yellow-50",
                green: "border-green-600 bg-green-50",
                red: "border-red-600 bg-red-50",
              }[n.code]
            } rounded-lg border-2  max-w-fit flex flex-row justify-center items-center`}
            role="alert"
          >
            <i
              className={`text-lg bi ${
                {
                  yellow: "bi-exclamation-circle-fill text-yellow-500",
                  green: "bi-check-circle-fill text-green-500",
                  red: "bi-exclamation-circle-fill text-red-500",
                }[n.code]
              } mr-2`}
            />
            <span className="font-semibold mr-1">{n.customer_name}</span>
            {n.message}
            <span className="font-thin ml-2">({n.timestamp})</span>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-4 gap-x-4 gap-y-8 mx-4 my-4">
        <div className="col-span-4 font-semibold text-2xl">Settings</div>
        <div className="px-4 pb-2 flex flex-row col-span-4">
          <div>
            <label className="font-semibold  text-gray-600 block">
              Billing Plan
            </label>
            <select
              className="form-input border-gray-300 rounded-md"
              value={billingPlan}
              onChange={(e) => {
                setBillingPlan(e.target.value);
              }}
            >
              <option value={null}>No Plan</option>
              {billingPlansQuery.data.data.map((plan) => (
                <option value={plan.id} key={plan.id}>
                  {plan.name}
                </option>
              ))}
            </select>
          </div>
          <button
            className="transition duration-200 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 text-white rounded-lg text-md shadow-sm hover:shadow-md font-semibold text-center flex-shrink px-4 py-[0.4rem] ml-2 mt-5 disabled:bg-gray-300 inline-flex flex-row items-center"
            onClick={() => {
              updateCustomerMutation.mutate({
                id: customerId,
                data: {
                  billing_plan: billingPlan,
                },
              });
            }}
            disabled={
              customerQuery.isLoading ||
              updateCustomerMutation.isLoading ||
              billingPlan === customer.billing_plan
            }
          >
            <span className="bi bi-check-lg mr-1 text-lg"></span>
            Update Plan
          </button>
        </div>
        <div className="col-span-4 font-semibold text-2xl">Revenue</div>
        <RevenueTotals customerId={customerId} className="col-span-1" />
        <RevenueByEndpoint customerId={customerId} className="col-span-3" />
        <div className="col-span-4 font-semibold text-2xl">Requests</div>
        <RequestsGraph customerId={customerId} className="col-span-2" />
        <RequestsByPathGraph customerId={customerId} className="col-span-1" />
        <CustomChart
          className="col-span-1"
          ys={[
            [0.23, 0.77].map((n) =>
              parseInt(n * TODO_REMOVE_HARDCODED_TOTAL_REQUESTS)
            ),
          ]}
          x={["v1", "v2"]}
          title="Requests by API Version"
          type="pie"
        />
        <CustomChart
          className="col-span-1"
          ys={[
            [0.08, 0.09, 0.18, 0.16, 0.17, 0.15, 0.15].map((n) =>
              parseInt(n * TODO_REMOVE_HARDCODED_TOTAL_REQUESTS)
            ),
          ]}
          x={["Sa", "Su", "M", "Tu", "W", "Th", "F"]}
          title="Requests by Day of Week"
          type="bar"
          subtitle=""
        />
        <CustomChart
          className="col-span-2"
          ys={[
            [
              0.01, 0.01, 0.02, 0.025, 0.025, 0.028, 0.03, 0.031, 0.035, 0.037,
              0.031, 0.04, 0.044, 0.046, 0.048, 0.049, 0.051, 0.055, 0.043,
              0.041, 0.038, 0.035, 0.025, 0.014,
            ].map((n) => parseInt(n * TODO_REMOVE_HARDCODED_TOTAL_REQUESTS)),
          ]}
          x={[
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24,
          ]}
          title="Requests by Time of Day"
          type="bar"
          subtitle="24-hour format"
        />
        <CustomChart
          className="col-span-1"
          ys={[
            [0.32, 0.6, 0.08].map((n) =>
              parseInt(n * TODO_REMOVE_HARDCODED_TOTAL_REQUESTS)
            ),
          ]}
          x={["SDK v1", "SDK v2", "SDK v2.1"]}
          title="Requests by SDK Version"
          type="pie"
        />
        <div className="col-span-4 font-semibold text-2xl">Responses</div>
        <RequestsByStatusGraph customerId={customerId} className="col-span-3" />
        <ResponseCodesChart customerId={customerId} className="col-span-1" />
        <div className="col-span-4 font-semibold text-2xl">Recent Errors</div>
        <ErrorTable customerId={customerId} className="col-span-4" />
      </div>
    </>
  );
}

export default CustomerOverview;
