import DashboardWidget from "./DashboardWidget";
import { useQuery } from "react-query";
import { getRequestsByPath, TODO_FIX_PATH_NAME } from "../../util/api";
import Chart from "react-apexcharts";
import { Loader } from "rsuite";

function RequestsByPathGraph({ className, customerId }) {
  const { isLoading, error, data } = useQuery(
    ["requests-all-by-path", customerId],
    getRequestsByPath(customerId)
  );

  if (isLoading) {
    return (
      <DashboardWidget className={className}>
        <Loader size="md" center />
      </DashboardWidget>
    );
  }

  if (error) {
    console.log(error);
  }

  // TODO: This is very very inefficient, need to improve.
  let buckets = [...new Set(data.data.map((d) => d.bucket))].sort();
  let series = {};
  data.data.forEach((item) => {
    if (series[item.path] === undefined) {
      series[item.path] = [];
    }
    series[item.path][buckets.indexOf(item.bucket)] = parseInt(item.count);
  });
  series = Object.keys(series).map((key) => ({
    name: TODO_FIX_PATH_NAME(key),
    data: series[key],
  }));
  buckets = buckets.map((bucket) => new Date(bucket).getTime());

  // Fill in empty spots in the data with 0
  for (let i = 0; i < buckets.length; i++) {
    for (let j = 0; j < series.length; j++) {
      if (series[j].data[i] === undefined) {
        series[j].data[i] = 0;
      }
    }
  }

  const options = {
    chart: {
      animations: {
        enabled: false,
        animateGradually: {
          enabled: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "datetime",
      categories: buckets,
    },
    tooltip: {
      x: {
        format: "HH:mm:ss on dd MMM yyyy ",
      },
    },
  };

  return (
    <DashboardWidget className={className}>
      <div className="flex items-center justify-between mb-4">
        <div className="flex-shrink-0">
          <h3 className="text-xl font-bold text-gray-900 mb-2">
            Requests by Endpoint
          </h3>
        </div>
      </div>
      <div id="main-chart">
        <Chart
          options={options}
          series={series}
          type="area"
          width="100%"
          height="300"
        />
      </div>
    </DashboardWidget>
  );
}

export default RequestsByPathGraph;
