import { Outlet } from "react-router-dom";
import Dashboard from "../../layouts/Dashboard";

const sidebarMenu = [
  { name: "Overview", path: "/dashboard", icon: "clipboard-data" },
  { name: "Customers", path: "/dashboard/customers", icon: "people" },
  {
    name: "Billing Plans",
    path: "/dashboard/billing-plans",
    icon: "cash-stack",
  },
  { name: "Integrations", path: "/dashboard/integrations", icon: "link-45deg" },
  { name: "Settings", path: "/dashboard/settings", icon: "gear" },
];

function AdminDashboard() {
  return (
    <Dashboard sidebarMenu={sidebarMenu}>
      <Outlet />
    </Dashboard>
  );
}

export default AdminDashboard;
