import logo from "../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";

function Sidebar({ items }) {
  let location = useLocation();

  function renderItems() {
    return items.map((item) => {
      return (
        <Link
          key={item.name}
          to={item.path}
          className={`p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 active:no-underline focus:no-underline hover:no-underline text-white ${
            location.pathname === item.path && "bg-gray-700"
          }`}
        >
          <i className={`bi bi-${item.icon} text-white`}></i>
          <span className="text-[15px] ml-4 text-gray-200 font-bold">
            {item.name}
          </span>
        </Link>
      );
    });
  }

  return (
    <div className="sidebar top-0 bottom-0 lg:left-0 p-2 w-[300px] min-w-[300px] overflow-y-auto text-center bg-gray-900">
      <div className="text-gray-100 text-xl mb-12">
        <div className="p-2.5 m-1 my-2 flex items-center justify-center">
          <img className="h-8" src={logo} />
        </div>
      </div>
      {renderItems()}
    </div>
  );
}

export default Sidebar;
