import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

function Dashboard({ children, sidebarMenu }) {
  return (
    <div className="flex flex-row h-full">
      <Sidebar items={sidebarMenu} />
      <div className="flex flex-col flex-grow">
        <Header />
        <div className="bg-gray-200 flex-grow overflow-auto">{children}</div>
      </div>
    </div>
  );
}

export default Dashboard;
