import DashboardWidget from "./DashboardWidget";
import { useQuery } from "react-query";
import { getResponseCodes } from "../../util/api";
import Chart from "react-apexcharts";
import { Loader } from "rsuite";

const blues = ["0277BD", "0288D1", "1E88E5", "2196F3", "42A5F5", "29B6F6"];
const yellows = ["F9A825", "FBC02D", "FDD835", "FFEB3B", "FFCA28"];
const reds = ["D84315", "E64A19", "F4511E", "FF5722", "FF7043"];

function ResponseCodesChart({ className, customerId }) {
  const { isLoading, error, data } = useQuery(
    ["response-codes-all", customerId],
    getResponseCodes(customerId)
  );

  if (isLoading) {
    return (
      <DashboardWidget className={className}>
        <Loader size="md" center />
      </DashboardWidget>
    );
  }

  if (error) {
    console.log(error);
  }

  let [bIdx, rIdx, yIdx] = [
    blues.length - 1,
    reds.length - 1,
    yellows.length - 1,
  ];

  const codes = data.data.map((d) => d.status);
  const counts = data.data.map((d) => parseInt(d.count));
  const colors = codes.map((code) => {
    if (code < 300) {
      return `#${blues[bIdx--]}`;
    } else if (code < 400) {
      return `#${yellows[yIdx--]}`;
    } else {
      return `#${reds[rIdx--]}`;
    }
  });

  const options = {
    dataLabels: {
      enabled: false,
    },
    colors: colors,
    labels: codes,
  };

  return (
    <DashboardWidget className={`${className} flex flex-col`}>
      <div className="flex items-center justify-between mb-4">
        <div className="flex-shrink-0">
          <h3 className="text-xl font-bold text-gray-900 mb-2">
            API Responses
          </h3>
          <span className="text-base font-normal text-gray-500">
            HTTP Status Code
          </span>
        </div>
      </div>
      <div id="main-chart" className="flex-grow flex">
        <Chart
          options={options}
          series={counts}
          type="pie"
          width="100%"
          height="300"
          className="flex-grow flex justify-center flex-col"
        />
      </div>
    </DashboardWidget>
  );
}

export default ResponseCodesChart;
